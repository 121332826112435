import React from 'react'
import line from "../../static/images/card_images/line.svg";
import Contacts from "../../components/contacts";

function About() {
    return (
        <div className='max-w-5xl mx-auto mt-10 font-[Manrope]'>
            <div className='my-3'>
                <p className='text-4xl font-bold mb-[10px] text-center'>О нас</p>
                <img className='mx-auto mb-12' src={line} alt="line" />
                <p className='text-gray-500 pb-4'>
                    Наше Туристическая компания - Travelcontinent была основана в сентябре 2019 года. Мы оказываем туристические услуги по всему миру.
                    Ставим акцент на арабские страны как Арабские Эмираты и Саудовская Аравия.
                    Наше компания даёт нашим клиентам возможность путешествовать со своими семьями в соответствии с их религиозными убеждениями.
                </p>

                <p className='text-gray-500 pb-4'>
                    Компания предоставляет услуги B2B напрямую туристическим агентствам и услуги B2C клиентам.
                    Дружелюбный и квалифицированный персонал компании будет обслуживать вас 24/7 и постарается сделать ваши путешествия безопасными, веселыми и по-настоящему бескомпромиссными.
                </p>

                <p className="text-gray-500 pb-4">
                    Мы рады создавать удобство для вас!
                </p>

                <p className="text-gray-500 pb-4">
                    Отбирая отели, сотрудники ALT* лично проверяют качество предоставляемого сервиса и уровень безопасности.
                    Компания ALT*, сотрудничая только с надежными проверенными партнерами-авиакомпаниями, гарантирует своим клиентам высокую степень безопасности, комфорта и четкости выполнения полетов.
                </p>
                <p className='text-gray-500 pb-4'>
                    Travelcontinent – национальный туроператор, специализирующийся на международном выездном, въездном и внутреннем туризме.
                </p>
                <p className='text-gray-500'>
                    Только проверенные партнеры Отбирая отели, сотрудники ALT* лично проверяют качество предоставляемого сервиса и уровень безопасности. Компания ALT*, сотрудничая только с надежными проверенными партнерами-авиакомпаниями, гарантирует своим клиентам высокую степень безопасности, комфорта и четкости выполнения полетов.
                </p>
                <p className='font-semibold my-6'>Цели и миссия</p>
                <p className='text-gray-500'>
                    Миссия Travelcontinent заключается в том, чтобы максимально способствовать созданию цивилизованного туристского рынка, где отношения в цепочке клиент - агент - оператор основаны на взаимном доверии и уважении. Конечная цель деятельности компании - сделать качественный отдых доступным для всех категорий населения Узбекистана.
                </p>
            </div>
            <div className='mt-[100px]'>
                <p className='text-4xl font-bold mb-[10px] text-center'>О миссии</p>
                <img className='mx-auto mb-12' src={line} alt="line" />
                <p className='text-gray-500 mb-8'>
                    <span className='text-red-600'>Travelcontinent</span> – национальный туроператор, специализирующийся на международном выездном, въездном и внутреннем туризме.
                </p>
                <p className='text-gray-500'>
                    <b>Только проверенные партнеры</b>
                    Отбирая отели, сотрудники ALT* лично проверяют качество предоставляемого сервиса и уровень безопасности. Компания ALT*, сотрудничая только с надежными проверенными партнерами-авиакомпаниями, гарантирует своим клиентам высокую степень безопасности, комфорта и четкости выполнения полетов.
                </p>
                <p className='font-semibold my-6'>Цели и миссия</p>
                <p className='text-gray-500'>
                    Миссия Travelcontinent заключается в том, чтобы максимально способствовать созданию цивилизованного туристского рынка, где отношения в цепочке клиент - агент - оператор основаны на взаимном доверии и уважении. Конечная цель деятельности компании - сделать качественный отдых доступным для всех категорий населения Узбекистана.
                </p>
            </div>
            <hr className='my-6' />
            <Contacts/>
        </div>
    )
}

export default About