const meals = [
    {
        "@inc": "-2147483647",
        "@status": "",
        "@stamp": "0x0000000002056b8f"
    },
    {
        "@inc": "2",
        "@status": "",
        "@name": "unknown meal",
        "@lname": "unknown meal",
        "@stamp": "0x0000000002056b91"
    },
    {
        "@inc": "3",
        "@status": "",
        "@name": "BB",
        "@lname": "BB",
        "@stamp": "0x0000000002056b92"
    },
    {
        "@inc": "4",
        "@status": "",
        "@name": "HB",
        "@lname": "HB",
        "@stamp": "0x0000000002056b93"
    },
    {
        "@inc": "5",
        "@status": "",
        "@name": "AI",
        "@lname": "AI",
        "@stamp": "0x0000000002056b94"
    },
    {
        "@inc": "6",
        "@status": "",
        "@name": "FB",
        "@lname": "FB",
        "@stamp": "0x0000000002056b95"
    },
    {
        "@inc": "7",
        "@status": "",
        "@name": "RO",
        "@lname": "RO",
        "@stamp": "0x0000000002056b96"
    },
    {
        "@inc": "1",
        "@status": "",
        "@name": "Without meal",
        "@lname": "Without meal",
        "@stamp": "0x0000000006f8be33"
    },
    {
        "@inc": "8",
        "@status": "",
        "@name": "HB - Plus",
        "@lname": "HB - Plus",
        "@stamp": "0x00000000480796f9"
    },
    {
        "@inc": "9",
        "@status": "",
        "@name": "HB - Lunch",
        "@lname": "HB - Lunch",
        "@stamp": "0x00000000480796fb"
    },
    {
        "@inc": "10",
        "@status": "",
        "@name": "HB - Dinner",
        "@lname": "HB - Dinner",
        "@stamp": "0x00000000480796fd"
    },
    {
        "@inc": "14",
        "@status": "",
        "@name": "FB - Plus",
        "@lname": "FB - Plus",
        "@stamp": "0x00000000480796ff"
    },
    {
        "@inc": "15",
        "@status": "",
        "@name": "AI - Soft",
        "@lname": "AI - Soft",
        "@stamp": "0x0000000048079702"
    },
    {
        "@inc": "16",
        "@status": "",
        "@name": "UAI",
        "@lname": "UAI",
        "@stamp": "0x0000000048079704"
    },
    {
        "@inc": "17",
        "@status": "",
        "@name": "AI - Standard",
        "@lname": "AI - Standard",
        "@stamp": "0x0000000048079706"
    },
    {
        "@inc": "18",
        "@status": "",
        "@name": "AI - Gold",
        "@lname": "AI - Gold",
        "@stamp": "0x0000000048079708"
    },
    {
        "@inc": "19",
        "@status": "",
        "@name": "AI - Dine Around",
        "@lname": "AI - Dine Around",
        "@stamp": "0x000000004807970a"
    },
    {
        "@inc": "20",
        "@status": "",
        "@name": "AI - Premium",
        "@lname": "AI - Premium",
        "@stamp": "0x000000004807970c"
    },
    {
        "@inc": "21",
        "@status": "",
        "@name": "FB - Ultra",
        "@lname": "FB - Ultra",
        "@stamp": "0x000000004807970e"
    },
    {
        "@inc": "22",
        "@status": "",
        "@name": "By Programm",
        "@lname": "By Programm",
        "@stamp": "0x0000000048079710"
    },
    {
        "@inc": "23",
        "@status": "",
        "@name": "HB - Dine Around",
        "@lname": "HB - Dine Around",
        "@stamp": "0x0000000048079712"
    },
    {
        "@inc": "27",
        "@status": "",
        "@name": "HB - Sky Lounge",
        "@lname": "HB - Sky Lounge",
        "@stamp": "0x0000000048079714"
    },
    {
        "@inc": "28",
        "@status": "",
        "@name": "FB - Sky Lounge",
        "@lname": "FB - Sky Lounge",
        "@stamp": "0x0000000048079716"
    },
    {
        "@inc": "29",
        "@status": "",
        "@name": "HB - La Terrasse",
        "@lname": "HB - La Terrasse",
        "@stamp": "0x0000000048079718"
    },
    {
        "@inc": "30",
        "@status": "",
        "@name": "FB - La Terrasse",
        "@lname": "FB - La Terrasse",
        "@stamp": "0x000000004807971a"
    },
    {
        "@inc": "31",
        "@status": "",
        "@name": "HB - Premium",
        "@lname": "HB - Premium",
        "@stamp": "0x000000004807971c"
    },
    {
        "@inc": "32",
        "@status": "",
        "@name": "HB - Platinum",
        "@lname": "HB - Platinum",
        "@stamp": "0x000000004807971e"
    },
    {
        "@inc": "34",
        "@status": "",
        "@name": "AI - Classic",
        "@lname": "AI - Classic",
        "@stamp": "0x0000000048079720"
    },
    {
        "@inc": "35",
        "@status": "",
        "@name": "FB - Dine Around",
        "@lname": "FB - Dine Around",
        "@stamp": "0x0000000048079722"
    },
    {
        "@inc": "36",
        "@status": "",
        "@name": "HB - Deluxe",
        "@lname": "HB - Deluxe",
        "@stamp": "0x0000000048079724"
    },
    {
        "@inc": "38",
        "@status": "",
        "@name": "FB - Deluxe",
        "@lname": "FB - Deluxe",
        "@stamp": "0x0000000048079726"
    },
    {
        "@inc": "39",
        "@status": "",
        "@name": "FB - Premium",
        "@lname": "FB - Premium",
        "@stamp": "0x0000000048079728"
    },
    {
        "@inc": "42",
        "@status": "",
        "@name": "AI - Deluxe",
        "@lname": "AI - Deluxe",
        "@stamp": "0x000000004807972a"
    },
    {
        "@inc": "43",
        "@status": "",
        "@name": "AI - Silver",
        "@lname": "AI - Silver",
        "@stamp": "0x000000004807972c"
    },
    {
        "@inc": "44",
        "@status": "",
        "@name": "HB - Premium Dinner",
        "@lname": "HB - Premium Dinner",
        "@stamp": "0x000000004807972e"
    },
    {
        "@inc": "45",
        "@status": "",
        "@name": "AI - Ultra Soft",
        "@lname": "AI - Ultra Soft",
        "@stamp": "0x0000000048079730"
    },
    {
        "@inc": "46",
        "@status": "",
        "@name": "AI - Mirage",
        "@lname": "AI - Mirage",
        "@stamp": "0x0000000069192ed9"
    },
    {
        "@inc": "47",
        "@status": "",
        "@name": "HB - Dine Around Plus",
        "@lname": "HB - Dine Around Plus",
        "@stamp": "0x0000000069568318"
    },
    {
        "@inc": "48",
        "@status": "",
        "@name": "FB - Dine Around Plus",
        "@lname": "FB - Dine Around Plus",
        "@stamp": "0x000000006956831a"
    },
    {
        "@inc": "49",
        "@status": "",
        "@name": "HB Plus - Dinner",
        "@lname": "HB Plus - Dinner",
        "@stamp": "0x000000007fe5534d"
    },
    {
        "@inc": "50",
        "@status": "",
        "@name": "FB - Extraordinary",
        "@lname": "FB - Extraordinary",
        "@stamp": "0x00000000831a0c37"
    },
    {
        "@inc": "33",
        "@status": "",
        "@name": "HB - Premium Dine Around",
        "@lname": "HB - Premium Dine Around",
        "@stamp": "0x00000000860fec76"
    },
    {
        "@inc": "51",
        "@status": "",
        "@name": "FB - Premium Dine Around",
        "@lname": "FB - Premium Dine Around",
        "@stamp": "0x00000000860fec78"
    },
    {
        "@inc": "52",
        "@status": "",
        "@name": "AI - Plus",
        "@lname": "AI - Plus",
        "@stamp": "0x00000000879a407a"
    },
    {
        "@inc": "53",
        "@status": "",
        "@name": "AI - Premium Mirage",
        "@lname": "AI - Premium Mirage",
        "@stamp": "0x000000008c11d130"
    },
    {
        "@inc": "54",
        "@status": "",
        "@name": "HB - Plus Dine Around",
        "@lname": "HB - Plus Dine Around",
        "@stamp": "0x00000000b0fb970f"
    },
    {
        "@inc": "24",
        "@name": "-",
        "@status": "D",
        "@stamp": "0x000000000ac97042"
    },
    {
        "@inc": "25",
        "@name": "-",
        "@status": "D",
        "@stamp": "0x000000000ac97044"
    },
    {
        "@inc": "26",
        "@name": "-",
        "@status": "D",
        "@stamp": "0x000000000ac970be"
    },
    {
        "@inc": "37",
        "@name": "-",
        "@status": "D",
        "@stamp": "0x0000000048069ac2"
    },
    {
        "@inc": "41",
        "@name": "-",
        "@status": "D",
        "@stamp": "0x000000004807468a"
    },
    {
        "@inc": "40",
        "@name": "-",
        "@status": "D",
        "@stamp": "0x00000000480796be"
    }
]

function GetMealName(id) {
   return meals.filter(meal => meal["@inc"] === id?.meal)[0]["@name"]
}

export default GetMealName;