const hotelsTownLists = [
    {id: 59595959, title: "Tashkent", ltitle: "Tashkent"},
    {id: 5, title: "Dubai", ltitle: "Dubai"},
    {id: 37, title: "Abu Dhabi", ltitle: "Abu Dhabi"},
    {id: 36, title: "Sharjah", ltitle: "Sharjah"},
    {id: 32, title: "Ajman", ltitle: "Ajman"},
    {id: 104, title: "Al Ain", ltitle: "Al Ain"},
    {id: 35, title: "Ras Al Khaimah", ltitle: "Ras Al Khaimah"},
    {id: 33, title: "Fujairah", ltitle: "Fujairah"},
    {id: 70, title: "Umm Al Quwain", ltitle: "Umm Al Quwain"},
]


export default hotelsTownLists
