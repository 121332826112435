 export const userRole = {
    admin: "admin",
    agent: "agent",
    client: "client"
}

export const gender = {
    male: "MALE",
    female: "FEMALE"
}
